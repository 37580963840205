<template>
  <el-popover ref="popper" placement="bottom" width="200" trigger="click" @show="onShow">
    <el-select
      filterable
      ref="select"
      :value="currentCompany"
      @change="selectCompany($event, row.dispatchId, row.id)"
    >
      <el-option
        v-for="(item, index) of companyOptions"
        :key="index"
        :label="`${item.companyAbbr}`"
        :value="item.id"
      ></el-option>
    </el-select>
    <el-tag slot="reference" class="selectable">{{row.companyName}}</el-tag>
  </el-popover>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { debounce } from 'throttle-debounce'

export default {
  name: 'company-list-dropdown',
  props: {
    row: {
      type: Object,
      default: () => {
        return {}
      }
    },
    companyOptions: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      currentCompany: '',
    }
  },
  methods: {
    selectCompany (companyId, dispatchId, dispatchTaskId) {
      if (this.row.number) {
        this.$confirm('调运任务已经派遣了车辆，修改所属公司需要重新派遣车辆', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.doChange(companyId, dispatchId, dispatchTaskId)
        }).catch(() => { })
      } else {
        this.doChange(companyId, dispatchId, dispatchTaskId)
      }
     },
      async doChange(companyId, dispatchId, dispatchTaskId) {
        const data = await this.$http({
          url: this.$http.adornUrl('/dispatch/updateCompany'),
          method: 'post',
          data: {
            companyId, dispatchId, dispatchTaskId
          }
        })
        if (data.code === 200) {
          this.$emit('close')
          this.$refs.popper.doClose()
        }
      },
      onShow () {
      this.$nextTick(() => {
        this.$refs.select.focus()
      })
    }
  }
}
</script>
