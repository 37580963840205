<template>
  <el-popover ref="popper" placement="bottom" width="200" trigger="click" @show="onShow">
    <el-select
      ref="select"
      :value="currentCar"
      filterable
      remote
      placeholder="请输入关键词"
      :remote-method="searchCarOptions"
      :loading="loadingCarOptions"
      @change="selectCar($event, row.dispatchId, row.id)"
    >
      <el-option
        v-for="(item, index) of carOptions"
        :key="index"
        :label="`${item.number} ${item.carSize}米 ${item.dispatchTime || '暂无调运'}`"
        :value="item.id"
      ></el-option>
    </el-select>
    <el-tag slot="reference" class="selectable">{{row.demandCarSize}}</el-tag>
  </el-popover>
</template>

<script>
import { debounce } from 'throttle-debounce'

export default {
  name: 'car-list-dropdown',
  props: {
    row: {
      type: Object,
      default: () => {
        return {}
      }
    },
    type: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      currentCar: '',
      carOptions: [],
      loadingCarOptions: true
    }
  },
  methods: {
    async search (query) {
      if (query !== '') {
        this.loadingCarOptions = true
        let params = { number: query }
        if (this.row.companyId) {
          params.companyId = this.row.companyId
        }
        if (this.row.dispatchTaskId) {
          params.dispatchTaskId = this.row.dispatchTaskId
        }
        const data = await this.$http({
          url: this.$http.adornUrl('/dispatch/dispatchCarList'),
          method: 'post',
          data: params
        })
        this.loadingCarOptions = false
        this.carOptions = data.datas
      } else {
        this.carOptions = []
      }
    },
    async selectCar (carId, dispatchId, dispatchTaskId) {
      console.log(carId, dispatchId, dispatchTaskId)
      const data = await this.$http({
        url: this.$http.adornUrl('/dispatch/updateCar'),
        method: 'post',
        data: {
          carId, dispatchId, dispatchTaskId
        }
      })
      if (data.code === 200) {
        this.$emit('close')
        this.$refs.popper.doClose()
      }
    },
    onShow () {
      this.$nextTick(() => {
        this.$refs.select.focus()
        this.carOptions = []
        this.loadingCarOptions = true
      })
    }
  },
  created () {
    this.searchCarOptions = debounce(1000, (query) => {
      this.search(query)
    })
  }
}
</script>
